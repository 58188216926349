/**
 * Function for easily omitting data from an object, supports either single keys to omit or an array of keys
 * @example
 * omit({ id: 1, name: "test" }, "name")
 * // { id: 1 }
 * omit({ id: 1, name: "test", hasPizza: true }, ["name", "hasPizza"])
 * // { id: 1 }
 */
export const omit = (obj: any, fields: string | string[]) => {
  if (!obj) return

  const shallowCopy = Object.assign({}, obj)

  if (typeof fields === 'string') {
    delete shallowCopy[fields]
  } else {
    for (let i = 0; i < fields.length; i += 1) {
      const key = fields[i]
      delete shallowCopy[key]
    }
  }

  return shallowCopy
}
